
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuizQuestion',

  props: {
    question: String
  }
});
