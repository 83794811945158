
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YesButton',

  props: {
    text: String
  }
});
