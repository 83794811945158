<template>
  <div class="d-none d-md-block" style="cursor: pointer;" @click="$emit('restartQuiz')">
    <svg width="100%" height="100%">
      <text x="50%" y="60%" text-anchor="middle" style="font-size: 80px;">
        Restart quiz ➜
      </text>
    </svg>
  </div>

  <div class="d-md-none" style="cursor: pointer; min-height: 150px;" @click="$emit('restartQuiz')">
    <svg width="100%" height="100%">
      <text x="50%" y="40%" text-anchor="middle" style="font-size: 80px;">
        Restart
      </text>
      <text x="50%" y="85%" text-anchor="middle" style="font-size: 80px;">
        quiz ➜
      </text>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RestartQuizButton',

  emits: ['restartQuiz']
});
</script>

<style scoped lang="scss">

</style>
