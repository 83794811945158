<template>
  <PageTitle title="Is it a scam?" />

  <div class="quiz">
    <section
      id="landing-section"
      class="quiz-section"
      :class="{ 'quiz-section-hide': showQuestionOne }"
    >
      <div class="d-none d-md-block" style="cursor: pointer" @click="showQuestionOne = true">
        <svg width="100%" height="100%">
          <text x="50%" y="60%" text-anchor="middle">
            Start quiz ➜
          </text>
        </svg>
      </div>

      <div
        class="mt-5 d-md-none"
        style="cursor: pointer; min-height: 300px;"
        @click="showQuestionOne = true"
      >
        <svg width="100%" height="100%">
          <text x="50%" y="30%" text-anchor="middle" style="font-size: 80px;">
            Start
          </text>
          <text x="50%" y="60%" text-anchor="middle" style="font-size: 80px;">
            quiz
          </text>
          <text x="50%" y="90%" text-anchor="middle" style="font-size: 80px;">
            ➜
          </text>
        </svg>
      </div>
    </section>

    <section
      id="are-you-bitboy"
      class="quiz-section"
      :class="{
        'quiz-section-hide':
          !showQuestionOne ||
          showQuestionTwo ||
          showBitboyError  ||
          showScamWarning ||
          showAGoodThingMessage
      }"
    >
      <QuizQuestion question="Are you Bitboy?" />
      <div>
        <YesButton text="Yes" @click="showQuestionTwo = true" />
        <NoButton text="No" @click="showBitboyError = true" />
      </div>
    </section>

    <section
      id="contacted-by-crypto-vc"
      class="quiz-section"
      :class="{
        'quiz-section-hide':
          !showQuestionTwo ||
          showBitboyError  ||
          showScamWarning ||
          showAGoodThingMessage
      }"
    >
      <QuizQuestion question="Did a crypto VC contact you to make a promo video?" />
      <div>
        <YesButton text="Yes" @click="showScamWarning = true" />
        <NoButton text="No" @click="showAGoodThingMessage = true" />
      </div>
    </section>

    <section
      id="not-bitboy"
      class="quiz-section"
      :class="{ 'quiz-section-hide': !showBitboyError }"
    >
      <div class="text-center">
        <div class="mb-5">
          <p>
            Lucky you, you're not Bitboy!
          </p>
          <p>
            You're smart enough to determine that 'Decentralised Fitness' is a at a minimum a joke
            and at worst a scam!
          </p>
        </div>
        <RestartQuizButton @restart-quiz="restartQuiz()" />
      </div>
    </section>

    <section
      id="scam-warning"
      class="quiz-section"
      :class="{ 'quiz-section-hide': !showScamWarning }"
    >
      <div class="text-center">
        <div class="mb-5">
          <p>
            Whoa there cowboy, this might be a scam!
          </p>
          <p>
            You probably shouldn't make that promo video!
          </p>
        </div>
        <RestartQuizButton @restart-quiz="restartQuiz()" />
      </div>
    </section>

    <section
      id="a-good-thing"
      class="quiz-section"
      :class="{ 'quiz-section-hide': !showAGoodThingMessage }"
    >
      <div class="text-center">
        <div class="mb-5">
          <p>
            Due to your inability to spot scam projects with no likelihood of success, having no VC
            crypto projects contact you is best!
          </p>
        </div>
        <RestartQuizButton @restart-quiz="restartQuiz()" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NoButton from './NoButton.vue';
import PageTitle from './PageTitle.vue';
import QuizQuestion from './QuizQuestion.vue';
import RestartQuizButton from './RestartQuizButton.vue';
import YesButton from './YesButton.vue';

export default defineComponent({
  name: 'Quiz',

  components: {
    NoButton,
    PageTitle,
    QuizQuestion,
    RestartQuizButton,
    YesButton
  },

  data() {
    return {
      showQuestionOne: false,
      showQuestionTwo: false,
      showQuestionThree: false,
      showBitboyError: false,
      showScamWarning: false,
      showAGoodThingMessage: false
    };
  },

  methods: {
    restartQuiz() {
      this.showQuestionOne = false;
      this.showQuestionTwo = false;
      this.showQuestionThree = false;
      this.showBitboyError = false;
      this.showScamWarning = false;
      this.showAGoodThingMessage = false;
    }
  }
});
</script>

<style scoped lang="scss">
.quiz {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  position: relative;
  width: 100%;
}

.quiz-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
}

.quiz-section-hide {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100vh);
}
</style>
