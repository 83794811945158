<template>
  <button
    type="button"
    class="btn btn-lg btn-outline-danger ms-4"
  >
    {{ text }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NoButton',

  props: {
    text: String
  }
});
</script>

<style scoped lang="scss">
.title {
  color: #3a3a3a;
  font-size: 94px;
  font-weight: bold;
}
</style>
