
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NoButton',

  props: {
    text: String
  }
});
