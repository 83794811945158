<template>
  <div class="container">
    <h1 class="title mt-5 d-none d-md-block">{{ title }}</h1>
    <h1 class="title mt-5 text-center d-md-none">{{ title }}</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageTitle',

  props: {
    title: String
  }
});
</script>

<style scoped lang="scss">
.title {
  color: #3a3a3a;
  font-size: 94px;
  font-weight: bold;
}
</style>
