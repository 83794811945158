<template>
  <div>
    <p class="mb-5 d-none d-md-block quiz-question">
      {{ question }}
    </p>
    <p class="mb-5 text-center d-md-none quiz-question">
      {{ question }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuizQuestion',

  props: {
    question: String
  }
});
</script>

<style scoped lang="scss">
.quiz-question {
  color: #0a2540;
  font-size: 38px;
  font-weight: bold;
}
</style>
