
import { defineComponent } from 'vue';
import IsThisAScam from './components/Quiz.vue';

export default defineComponent({
  name: 'App',
  components: {
    IsThisAScam
  }
});
