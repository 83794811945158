<template>
  <IsThisAScam />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IsThisAScam from './components/Quiz.vue';

export default defineComponent({
  name: 'App',
  components: {
    IsThisAScam
  }
});
</script>
