
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageTitle',

  props: {
    title: String
  }
});
