
import { defineComponent } from 'vue';
import NoButton from './NoButton.vue';
import PageTitle from './PageTitle.vue';
import QuizQuestion from './QuizQuestion.vue';
import RestartQuizButton from './RestartQuizButton.vue';
import YesButton from './YesButton.vue';

export default defineComponent({
  name: 'Quiz',

  components: {
    NoButton,
    PageTitle,
    QuizQuestion,
    RestartQuizButton,
    YesButton
  },

  data() {
    return {
      showQuestionOne: false,
      showQuestionTwo: false,
      showQuestionThree: false,
      showBitboyError: false,
      showScamWarning: false,
      showAGoodThingMessage: false
    };
  },

  methods: {
    restartQuiz() {
      this.showQuestionOne = false;
      this.showQuestionTwo = false;
      this.showQuestionThree = false;
      this.showBitboyError = false;
      this.showScamWarning = false;
      this.showAGoodThingMessage = false;
    }
  }
});
